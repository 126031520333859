import React from 'react';
import ServiceTemplate from './ServiceTemplate';
import { bannerImages, processImages } from '../../config/images';

const TaxServices: React.FC = () => {
  const serviceData = {
    title: "Tax Services",
    description: "Comprehensive tax planning and compliance services to optimize your tax position and ensure regulatory compliance.",
    heroImage: bannerImages.tax,
    keyFeatures: [
      {
        title: "Tax Planning & Strategy",
        description: "Strategic tax planning to optimize your tax position and minimize liabilities.",
        icon: "calculator"
      },
      {
        title: "VAT Services",
        description: "Complete VAT compliance, registration, filing, and advisory services.",
        icon: "file_text"
      },
      {
        title: "Corporate Tax",
        description: "Expert guidance on corporate tax matters and compliance requirements.",
        icon: "building"
      },
      {
        title: "Tax Compliance",
        description: "Ensuring adherence to all applicable tax regulations and deadlines.",
        icon: "check_circle"
      },
      {
        title: "International Tax",
        description: "Navigate cross-border tax implications and international regulations.",
        icon: "globe"
      },
      {
        title: "Tax Advisory",
        description: "Expert advice on complex tax matters and strategic decisions.",
        icon: "briefcase"
      }
    ],
    benefits: [
      {
        title: "Tax Optimization",
        description: "Maximize tax efficiency while ensuring full compliance with regulations.",
        icon: "trending_up"
      },
      {
        title: "Risk Mitigation",
        description: "Identify and address potential tax risks before they become issues.",
        icon: "shield"
      },
      {
        title: "Cost Savings",
        description: "Optimize tax positions to reduce overall tax burden legally.",
        icon: "dollar_sign"
      },
      {
        title: "Peace of Mind",
        description: "Expert handling of all tax matters ensures compliance and accuracy.",
        icon: "check_square"
      }
    ],
    process: [
      {
        step: 1,
        title: "Initial Assessment",
        description: "Comprehensive review of your current tax position and requirements.",
        image: processImages.consultation
      },
      {
        step: 2,
        title: "Strategy Development",
        description: "Creation of tailored tax planning strategies aligned with your goals.",
        image: processImages.planning
      },
      {
        step: 3,
        title: "Implementation",
        description: "Execution of tax strategies and establishment of compliance processes.",
        image: processImages.implementation
      },
      {
        step: 4,
        title: "Ongoing Support",
        description: "Regular monitoring, updates, and adjustments to ensure optimization.",
        image: processImages.support
      }
    ]
  };

  return <ServiceTemplate {...serviceData} />;
};

export default TaxServices;