import React from 'react';
import { motion } from 'framer-motion';
import Button from '../../components/ui/Buttons';
import { ServiceIcons } from '../../components/services/ServiceIcons';
import { LucideIcon, LucideProps } from 'lucide-react';
import OptimizedImage from '../../components/OptimizedImage';
import { ServiceProps } from '../../types';

// Define interfaces for icon handling
interface IconTypeProps extends LucideProps {
  size?: number | string;
  className?: string;
}

interface Feature {
  title: string;
  description: string;
  icon?: string;
}

interface Benefit {
  title: string;
  description: string;
  icon?: string;
}

const ServiceTemplate: React.FC<ServiceProps> = ({
  title,
  description,
  keyFeatures,
  benefits,
  process,
  heroImage = "/images/services/banners/default/default_1280.webp",
}) => {
  const getProcessImage = (step: number): string => {
    return `/images/process/step${step}`;
  };

  const renderIcon = (iconName: string | undefined, size: "small" | "large") => {
    if (!iconName) return null;
    
    const sizes = {
      small: {
        container: "w-12 h-12",
        icon: "w-6 h-6"
      },
      large: {
        container: "w-16 h-16",
        icon: "w-8 h-8"
      }
    };

    // Safely get the icon component
    const IconComponent = (ServiceIcons as any)[iconName] || ServiceIcons.bookkeeping;

    return (
      <div className={`bg-[#5D4A82]/10 ${sizes[size].container} ${size === 'large' ? 'rounded-full' : 'rounded-lg'} flex items-center justify-center mb-4 ${size === 'large' ? 'mx-auto' : ''}`}>
        <IconComponent className={`${sizes[size].icon} text-[#5D4A82]`} />
      </div>
    );
  };

  const BenefitCard: React.FC<{ benefit: Benefit; index: number }> = ({ benefit, index }) => (
    <motion.div
      key={benefit.title}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1 }}
      className="text-center"
    >
      {benefit.icon && renderIcon(benefit.icon, "large")}
      <h3 className="text-xl font-semibold text-[#5D4A82] mb-3">
        {benefit.title}
      </h3>
      <p className="text-gray-600">
        {benefit.description}
      </p>
    </motion.div>
  );

  const FeatureCard: React.FC<{ feature: Feature; index: number }> = ({ feature, index }) => (
    <motion.div
      key={feature.title}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1 }}
      className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
    >
      {feature.icon && renderIcon(feature.icon, "small")}
      <h3 className="text-xl font-semibold text-[#5D4A82] mb-3">
        {feature.title}
      </h3>
      <p className="text-gray-600">
        {feature.description}
      </p>
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative pt-24">
        <div className="absolute inset-0 overflow-hidden h-[500px]">
          <OptimizedImage
            src={heroImage}
            alt={title}
            className="w-full h-full object-cover"
            priority={true}
            sizes="100vw"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-[#5D4A82]/90 to-[#856BAE]/90 mix-blend-multiply" />
        </div>
        
        <div className="relative container mx-auto px-4 pt-20 pb-40">
          <div className="max-w-3xl">
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-4xl md:text-5xl font-bold text-white mb-6"
            >
              {title}
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-lg text-white/90 mb-8"
            >
              {description}
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
            >
              <Button
                variant="cta-light"
                onClick={() => window.location.href = '/get-started'}
              >
                Get Started →
              </Button>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Key Features Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-6xl mx-auto"
          >
            <h2 className="text-3xl font-bold mb-16 text-[#5D4A82] text-center">
              Key Features
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {keyFeatures.map((feature, index) => (
                <FeatureCard key={feature.title} feature={feature} index={index} />
              ))}
            </div>
          </motion.div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-6xl mx-auto"
          >
            <h2 className="text-3xl font-bold mb-16 text-[#5D4A82] text-center">
              Benefits
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {benefits.map((benefit, index) => (
                <BenefitCard key={benefit.title} benefit={benefit} index={index} />
              ))}
            </div>
          </motion.div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-20">
        <div className="container mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-6xl mx-auto"
          >
            <h2 className="text-3xl font-bold mb-16 text-[#5D4A82] text-center">
              Our Process
            </h2>
            <div className="space-y-20">
              {process.map((step, index) => (
                <motion.div
                  key={step.step}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className={`flex flex-col ${
                    index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
                  } gap-12 items-center relative`}
                >
                  <div className="w-full lg:w-1/2">
                    <div className="relative">
                      <div className="absolute -top-4 -left-4 w-16 h-16 bg-[#5D4A82] text-white
                        rounded-xl flex items-center justify-center text-2xl font-bold shadow-lg z-10">
                        {step.step}
                      </div>
                      <OptimizedImage
                        src={step.image || getProcessImage(step.step)}
                        alt={step.title}
                        className="w-full h-[300px] lg:h-[400px] object-cover rounded-xl shadow-lg"
                        sizes="(max-width: 1024px) 100vw, 50vw"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent rounded-xl" />
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2">
                    <h3 className="text-2xl font-bold text-[#5D4A82] mb-4">
                      {step.title}
                    </h3>
                    <p className="text-gray-600">
                      {step.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-[#5D4A82] mb-6">
              Ready to Get Started?
            </h2>
            <p className="text-gray-600 mb-8">
              Contact us today to learn more about our {title.toLowerCase()} and how we can help your business succeed.
            </p>
            <Button
              variant="cta"
              onClick={() => window.location.href = '/get-started'}
            >
              Schedule a Free Consultation
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceTemplate;