import React from 'react';
import ServiceTemplate from './ServiceTemplate';
import { bannerImages, processImages } from '../../config/images';

const AccountingServices: React.FC = () => {
  const serviceData = {
    title: "Accounting Services",
    description: "Expert accounting solutions to help you maintain accurate financial records and make informed business decisions.",
    heroImage: bannerImages.accounting,
    keyFeatures: [
      {
        title: "Bookkeeping Services",
        description: "Comprehensive record-keeping solutions created with meticulous attention to detail and accuracy.",
        icon: "calculator"
      },
      {
        title: "Financial Statements",
        description: "Regular preparation of detailed financial statements to track your business performance.",
        icon: "chart"
      },
      {
        title: "Bank Reconciliation",
        description: "Regular reconciliation of bank statements to ensure accuracy and prevent discrepancies.",
        icon: "bank"
      },
      {
        title: "Payroll Management",
        description: "Complete payroll processing and management services for your workforce.",
        icon: "users"
      },
      {
        title: "Tax Planning",
        description: "Strategic tax planning and preparation to optimize your financial position.",
        icon: "file_text"
      },
      {
        title: "Financial Analysis",
        description: "In-depth analysis of financial data to support informed decision-making.",
        icon: "trending_up"
      }
    ],
    benefits: [
      {
        title: "Accurate Financial Records",
        description: "Well-maintained and organized records that help you track your business performance and make informed decisions",
        icon: "check_circle"
      },
      {
        title: "Time and Cost Savings",
        description: "Focus on growing your business while we handle your accounting needs, reducing the need for in-house accounting staff",
        icon: "clock"
      },
      {
        title: "Compliance Assurance",
        description: "Stay compliant with all relevant regulatory and tax requirements, avoiding potential penalties and legal issues",
        icon: "shield_check"
      },
      {
        title: "Expert Financial Guidance",
        description: "Receive professional advice and insights to help optimize your financial operations and improve business performance",
        icon: "star"
      }
    ],
    process: [
      {
        step: 1,
        title: "Initial Consultation",
        description: "We begin with a comprehensive meeting to understand your business needs, current accounting processes, and help identify the best way to serve your unique situation.",
        image: processImages.consultation
      },
      {
        step: 2,
        title: "Assessment and Planning",
        description: "Our team conducts a thorough evaluation of your current financial systems and develops a tailored accounting solution that aligns with your business objectives and compliance requirements.",
        image: processImages.planning
      },
      {
        step: 3,
        title: "Implementation",
        description: "Following agreement on the approach, we begin implementing the discussed solutions while ensuring minimal disruption to your business operations.",
        image: processImages.implementation
      },
      {
        step: 4,
        title: "Ongoing Support",
        description: "Regular monitoring and updates to ensure your accounting processes remain efficient and compliant.",
        image: processImages.support
      }
    ]
  };

  return <ServiceTemplate {...serviceData} />;
};

export default AccountingServices;