import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Calendar } from "lucide-react";
import vatReformsImage from "../assets/images/blogcontent/vatreformsimage.png";
import trademarkImage from "../assets/images/blogcontent/trademark.png";
import governanceImage from "../assets/images/blogcontent/scgovernance.png";
import taxTreatyImage from "../assets/images/blogcontent/UAETaxbenefits.png";

interface BlogPost {
  id: string;
  title: string;
  author: string;
  date: string;
  excerpt: string;
  content: {
    heading: string;
    paragraphs: string[];
  }[];
  imageUrl: string;
  tags: string[];
}

const BlogPage = () => {
  const [selectedArticle, setSelectedArticle] = useState<string | null>(null);

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: -50,
    },
    show: { 
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15,
      },
    },
  };

  const articles: BlogPost[] = [
    {
      id: "1",
      title: "Unlocking UAE Tax Benefits for Indian Investors: Insights from the Latest Tax Ruling",
      author: "Luminous Legal Partners",
      date: "2024-11-27",
      excerpt: "In a landmark ruling, the Delhi Income Tax Appellate Tribunal (ITAT) has provided a significant boost for Indian investors in the UAE by clarifying that actual payment of tax in the UAE is not required to claim benefits under the India-UAE Double Taxation Avoidance Agreement (DTAA). This ruling, stemming from Saket Kanoi vs. DCIT [2024] 168 Taxmann 418, underscores the importance of international tax treaties in fostering cross-border investments while ensuring fair and transparent taxation.",
      imageUrl: taxTreatyImage,
      tags: ["IndiaUAE", "DTAA", "TaxSavings", "CrossBorderInvestments", "GlobalTaxation", "IndianInvestors", "EconomicGrowth"],
      content: [
        {
          heading: "The Case in Focus",
          paragraphs: [
            "The ITAT dealt with an Indian investor residing in the UAE who earned capital gains from the sale of mutual funds in India. The taxpayer invoked Article 13(5) of the DTAA, which grants exclusive taxation rights for such gains to the UAE. Indian tax authorities, however, denied the exemption, arguing that since the investor had not paid any tax in the UAE, he was not entitled to the treaty benefit.",
            "'Liable to Tax' Refers to Taxing Rights, Not Payment - The term 'liable to tax' signifies that the UAE has the right to tax the individual, irrespective of whether such tax is actually levied. This interpretation ensures that the absence of tax enforcement in the UAE does not disqualify treaty benefits.",
            "India Must Honor Exclusive Taxation Rights - Under Article 13(5), the UAE retains exclusive rights to tax the capital gains in question, and India cannot impose tax on such income.",
            "Treaty Obligations Take Precedence - The judgment reinforces India's commitment to upholding its international treaty obligations, ensuring consistent and equitable treatment for cross-border taxpayers."
          ]
        },
        {
          heading: "Implications for Indian Investors in the UAE",
          paragraphs: [
            "Tax Savings - Capital gains from Indian investments, including shares and mutual funds, are exempt from Indian taxation under Article 13(5). The UAE, which typically does not impose personal income or capital gains tax, becomes an ideal jurisdiction for maximizing after-tax returns.",
            "Clarity and Confidence in Tax Planning - Indian investors can now plan cross-border investments with greater certainty, knowing that the absence of UAE tax payment does not affect their DTAA entitlements.",
            "Simplified Compliance - The clarification reduces compliance burdens and litigation risks for investors by eliminating ambiguity around treaty application.",
            "Investor Confidence - The decision aligns India's tax treaty interpretation with global norms, enhancing investor confidence and strengthening bilateral economic ties."
          ]
        },
        {
          heading: "Strategic Insights from International Tax Law",
          paragraphs: [
            "Non-Discrimination - The ruling ensures that treaty benefits are available to all UAE residents, even those who do not pay tax, fostering a fair taxation environment.",
            "Treaty Supremacy - The ITAT upholds the primacy of treaty provisions, ensuring India remains a treaty-compliant jurisdiction, critical for cross-border investments.",
            "Tax Neutrality - By preventing unnecessary taxation, the decision creates a tax-efficient environment for Indian investors to focus on wealth creation.",
            "The ITAT's decision in Saket Kanoi vs. DCIT is a landmark judgment for cross-border taxpayers, reaffirming the robustness of the India-UAE DTAA. Indian investors in the UAE can now leverage this framework to maximize tax savings, streamline compliance, and expand their financial horizons with confidence.",
            "By honoring international treaty principles, India has not only provided much-needed clarity for cross-border taxation but also strengthened its position as a global hub for equitable and efficient tax practices."
          ]
        }
      ]
    },
    // Keeping other articles as placeholders until you provide their content
    {
      id: "2",
      title: "UAE VAT Reforms Unveiled: Key Impacts on Your Business",
      author: "Luminous Legal Partners",
      date: "2024-11-19",
      excerpt: "The UAE Federal Tax Authority has introduced significant VAT reforms effective from November 15, 2024, bringing important changes to registration thresholds and compliance requirements.",
      imageUrl: vatReformsImage,
      tags: ["VAT", "Tax Reform", "Business Compliance", "UAE Tax", "Regulatory Updates"],
      content: [
        {
          heading: "New VAT Registration Thresholds",
          paragraphs: [
            "The mandatory VAT registration threshold remains at AED 375,000 in annual taxable turnover, while the voluntary registration threshold is maintained at AED 187,500.",
            "Businesses anticipating to exceed the mandatory threshold within 30 days must initiate registration procedures immediately to ensure compliance."
          ]
        },
        {
          heading: "Key Changes in VAT Regulations",
          paragraphs: [
            "The amendments introduce new rules for digital economy transactions and expand provisions for cross-border services.",
            "Significant changes have been made to input tax recovery rules and the treatment of specific business sectors, including real estate and financial services."
          ]
        },
        {
          heading: "Compliance and Reporting Updates",
          paragraphs: [
            "Enhanced documentation requirements have been introduced for certain transactions, particularly those involving zero-rated supplies and exempt activities.",
            "The reforms include updated penalties for non-compliance and late registration, emphasizing the importance of timely adherence to VAT obligations."
          ]
        }
      ]
    },
    // Other articles remain the same...
    {
      id: "3",
      title: "Is Your Service Brand Truly Protected? Understanding Trademark Essentials",
      author: "Luminous Legal Partners",
      date: "2024-11-09",
      excerpt: "In today's competitive market, protecting your service brand through proper trademark registration is crucial. Learn about the essential steps and considerations for securing your brand's identity.",
      imageUrl: trademarkImage,
      tags: ["Trademark Protection", "Brand Security", "Intellectual Property", "Legal Compliance", "Business Strategy"],
      content: [
        {
          heading: "The Importance of Trademark Registration",
          paragraphs: [
            "Trademark registration provides exclusive rights to your brand identity, preventing unauthorized use and potential market confusion.",
            "A registered trademark becomes a valuable business asset, enhancing your company's market value and providing legal protection against infringement."
          ]
        },
        {
          heading: "Key Steps in Trademark Protection",
          paragraphs: [
            "Conduct comprehensive trademark searches before registration to ensure uniqueness and avoid potential conflicts.",
            "Consider international trademark registration through the Madrid System for global brand protection, especially important in today's interconnected market."
          ]
        },
        {
          heading: "Maintaining Your Trademark Rights",
          paragraphs: [
            "Regular monitoring of market activities to detect potential infringement and maintaining proper documentation of trademark usage.",
            "Implementing a clear strategy for trademark enforcement and renewal to ensure continuous protection of your brand assets."
          ]
        }
      ]
    },
    {
      id: "4",
      title: "Why Do Boards Need a Resilient Governance Framework?",
      author: "Luminous Legal Partners",
      date: "2024-11-06",
      excerpt: "Effective corporate governance is crucial for organizational success and sustainability. Discover why a resilient governance framework is essential for modern boards and how to implement one.",
      imageUrl: governanceImage,
      tags: ["Corporate Governance", "Board Management", "Risk Management", "Business Strategy", "Compliance"],
      content: [
        {
          heading: "The Foundation of Resilient Governance",
          paragraphs: [
            "A resilient governance framework provides the structure for effective decision-making, risk management, and stakeholder engagement.",
            "It ensures transparency, accountability, and sustainable business practices while adapting to changing market conditions and regulatory requirements."
          ]
        },
        {
          heading: "Key Components of Effective Governance",
          paragraphs: [
            "Clear definition of roles and responsibilities at board and management levels, ensuring proper oversight and execution of strategic objectives.",
            "Robust risk management systems and internal controls that can adapt to emerging challenges and opportunities."
          ]
        },
        {
          heading: "Implementing Strong Governance Practices",
          paragraphs: [
            "Regular board evaluations and training programs to enhance effectiveness and maintain current knowledge of best practices.",
            "Development of clear policies and procedures for decision-making, succession planning, and stakeholder communication."
          ]
        }
      ]
    }
  ];

  const handleArticleSelect = (id: string) => {
    setSelectedArticle(selectedArticle === id ? null : id);
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-20">
      <div className="bg-gradient-to-b from-[#5D4A82] to-[#856BAE] text-white py-16">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">Our Insights</h1>
          <p className="text-xl text-white/90">
            Expert perspectives on legal trends, compliance, and business
            transformation
          </p>
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        <motion.div 
          className="max-w-4xl mx-auto space-y-4"
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          {articles.map((article) => (
            <motion.div
              key={article.id}
              className="rounded-xl overflow-hidden"
              variants={cardVariants}
            >
              <motion.button
                onClick={() => handleArticleSelect(article.id)}
                className="w-full text-left"
              >
                <div
                  className={`bg-[#5D4A82] p-6 flex justify-between items-center text-white rounded-xl
                    ${selectedArticle === article.id ? "rounded-b-none" : ""}`}
                >
                  <div className="flex-grow">
                    <div className="flex items-center text-white/80 text-sm mb-2">
                      <Calendar className="w-4 h-4 mr-2" />
                      <span>
                        {new Date(article.date).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </span>
                      <span className="mx-2">•</span>
                      <span>By {article.author}</span>
                    </div>
                    <h2 className="text-xl font-semibold">{article.title}</h2>
                  </div>
                  <div className="ml-4">
                    {selectedArticle === article.id ? (
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 15l7-7 7 7"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </motion.button>

              <AnimatePresence>
                {selectedArticle === article.id && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="bg-white border-2 border-t-0 border-[#5D4A82] rounded-b-xl"
                  >
                    <div className="p-6">
                      <div className="flex flex-wrap gap-2 mb-4">
                        {article.tags.map((tag, i) => (
                          <span
                            key={i}
                            className="px-3 py-1 bg-purple-100 text-[#5D4A82] rounded-full text-sm"
                          >
                            {tag}
                          </span>
                        ))}
                      </div>

                      <div className="relative overflow-hidden mb-6">
                        <img
                          src={article.imageUrl}
                          alt={article.title}
                          className="w-full h-72 object-cover object-center rounded-lg"
                        />
                      </div>

                      <p className="text-gray-600 mb-6">{article.excerpt}</p>

                      {article.content.map((section, idx) => (
                        <div key={idx} className="mb-8">
                          <h3 className="text-xl font-semibold text-[#5D4A82] mb-4">
                            {section.heading}
                          </h3>
                          {section.paragraphs.map((paragraph, i) => (
                            <p
                              key={i}
                              className="mb-4 text-gray-700 whitespace-pre-line"
                            >
                              {paragraph}
                            </p>
                          ))}
                        </div>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default BlogPage;