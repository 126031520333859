import React from 'react';
import PerspectiveHero from '../components/home/hero/PerspectiveHero';
import WhyUsHome from '../components/sections/WhyUsHome';
import IdealPartner3D from '../components/sections/IdealPartner3D';
import Locations from '../components/sections/Locations';
import FloatingCTA from '../components/Common/FloatingCTA';

const HomePage = () => {
  return (
    <>
      <PerspectiveHero />
      <WhyUsHome />
      <IdealPartner3D />
      <Locations />
      <FloatingCTA />
    </>
  );
};

export default HomePage;