// Import service banner images
import accountingBanner from '../assets/images/aboutimg/group-business-people-working-together-meeting-room.jpg';
import advisoryBanner from '../assets/images/aboutimg/successful-business-partners-discussing-contract.jpg';
import auditBanner from '../assets/images/aboutimg/close-up-colleagues-working-together.jpg';
import corporateBanner from '../assets/images/aboutimg/office2.jpg';
import legalBanner from '../assets/images/aboutimg/successful-business-partners-discussing-contract.jpg';
import taxBanner from '../assets/images/aboutimg/top-view-co-workers-planning-strategy.jpg';

// Process step images using the same images temporarily
const consultationImage = advisoryBanner;
const planningImage = taxBanner;
const implementationImage = accountingBanner;
const supportImage = auditBanner;

export const bannerImages = {
  accounting: accountingBanner,
  advisory: advisoryBanner,
  audit: auditBanner,
  corporate: corporateBanner,
  legal: legalBanner,
  tax: taxBanner,
};

export const processImages = {
  consultation: consultationImage,
  planning: planningImage,
  implementation: implementationImage,
  support: supportImage,
};

export default {
  bannerImages,
  processImages,
};