import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/ui/Buttons';

interface ServiceCard {
  title: string;
  description: string;
  icon: string;
  path: string;
  features: string[];
}

const serviceCards: ServiceCard[] = [
  {
    title: "Legal & Corporate Services",
    description: "Comprehensive legal solutions including corporate and commercial law, trademark protection, and expert guidance.",
    icon: "⚖️",
    path: "/services/legal",
    features: [
      "Corporate & Commercial Law",
      "Trademark Protection",
      "Legal Consultation",
      "Contract Review"
    ]
  },
  {
    title: "Corporate Governance",
    description: "Establishing robust governance frameworks and ensuring regulatory compliance for transparency.",
    icon: "🛡️",
    path: "/services/governance",
    features: [
      "Governance Framework",
      "Regulatory Compliance",
      "Risk Management",
      "Policy Development"
    ]
  },
  {
    title: "Formation & Structuring",
    description: "Expert assistance in business formation, corporate structuring, and strategic planning.",
    icon: "🏛️",
    path: "/services/formation",
    features: [
      "Business Formation",
      "Corporate Structuring",
      "Strategic Planning",
      "Efficiency Optimization"
    ]
  },
  {
    title: "Tax Services",
    description: "Expert tax planning and compliance services for businesses in the UAE.",
    icon: "📋",
    path: "/services/tax",
    features: [
      "Tax Planning",
      "VAT Services",
      "Corporate Tax",
      "Tax Compliance"
    ]
  },
  {
    title: "Audit & Assurance",
    description: "Comprehensive audit services ensuring operational efficiency and compliance.",
    icon: "🔍",
    path: "/services/audit",
    features: [
      "External Audit",
      "Internal Audit",
      "Compliance Audit",
      "Risk Assessment"
    ]
  },
  {
    title: "Business Advisory",
    description: "Strategic guidance and advisory services for business growth and success.",
    icon: "💡",
    path: "/services/advisory",
    features: [
      "Strategic Planning",
      "Business Growth",
      "Market Analysis",
      "Performance Optimization"
    ]
  }
];

function Services() {
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-[#5D4A82] to-[#856BAE] text-white">
        <div className="container mx-auto px-4 py-20">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-6">Our Services</h1>
            <p className="text-xl md:text-2xl text-gray-100">
              Comprehensive business solutions tailored to help your organization thrive in the UAE market.
            </p>
          </div>
        </div>
      </div>

      {/* Services Grid */}
      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {serviceCards.map((service, index) => (
            <Link
              key={index}
              to={service.path}
              className="group bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 p-6 flex flex-col"
            >
              <div className="flex items-center gap-4 mb-6">
                <div className="bg-[#5D4A82] p-4 rounded-lg">
                  <span className="text-3xl">{service.icon}</span>
                </div>
                <h2 className="text-xl font-bold text-[#5D4A82]">{service.title}</h2>
              </div>
              
              <p className="text-gray-600 mb-6">{service.description}</p>
              
              <div className="flex-grow">
                <ul className="space-y-2">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-gray-600">
                      <svg className="w-4 h-4 mr-2 text-[#856BAE]" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              
              <div className="mt-6 flex items-center text-[#5D4A82] font-semibold group-hover:text-[#856BAE]">
                Learn More 
                <span className="transform group-hover:translate-x-2 transition-transform ml-2">→</span>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto text-center mb-12">
            <h2 className="text-3xl font-bold mb-6 text-[#5D4A82]">Why Choose LBC?</h2>
            <p className="text-xl text-gray-600">
              Your trusted partner for business success in the UAE
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            <div className="text-center p-6">
              <div className="w-16 h-16 bg-[#5D4A82] rounded-lg flex items-center justify-center mx-auto mb-4">
                <span className="text-2xl text-white">🌟</span>
              </div>
              <h3 className="text-xl font-bold mb-3 text-[#5D4A82]">Expertise</h3>
              <p className="text-gray-600">Years of experience serving businesses in the UAE market</p>
            </div>
            <div className="text-center p-6">
              <div className="w-16 h-16 bg-[#5D4A82] rounded-lg flex items-center justify-center mx-auto mb-4">
                <span className="text-2xl text-white">🤝</span>
              </div>
              <h3 className="text-xl font-bold mb-3 text-[#5D4A82]">Personal Approach</h3>
              <p className="text-gray-600">Tailored solutions to meet your specific business needs</p>
            </div>
            <div className="text-center p-6">
              <div className="w-16 h-16 bg-[#5D4A82] rounded-lg flex items-center justify-center mx-auto mb-4">
                <span className="text-2xl text-white">🎯</span>
              </div>
              <h3 className="text-xl font-bold mb-3 text-[#5D4A82]">Results Driven</h3>
              <p className="text-gray-600">Focused on delivering measurable business outcomes</p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-[#5D4A82] text-white">
        <div className="container mx-auto px-4 py-16 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Get Started?</h2>
          <p className="text-xl text-gray-100 mb-8 max-w-2xl mx-auto">
            Contact us for a free consultation to discuss your business needs and how we can help you succeed in the UAE market.
          </p>
          <div className="flex justify-center gap-4">
            <Button
              variant="cta"
              size="lg"
              onClick={() => window.location.href = '/get-started'}
              className="bg-white text-[#5D4A82] hover:bg-gray-100"
            >
              Get Started
            </Button>
            <Button
              variant="secondary"
              size="lg"
              onClick={() => window.location.href = '/about'}
              className="border-2 border-white hover:bg-white hover:text-[#5D4A82]"
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;