import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import szrddubai from "../../../assets/images/szroad/szrddubai.png";

const PerspectiveHero = () => {
  const navigate = useNavigate();

  return (
    <section className="relative min-h-screen overflow-hidden">
      {/* Background structure */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-[#2D1B69] via-[#4C1D95] to-[#6B46C1]">
          <div 
            className="absolute inset-0 w-full h-full"
            style={{
              overflow: 'hidden',
              position: 'relative'
            }}
          >
            <img 
              src={szrddubai} 
              alt="Sheikh Zayed Road"
              className="absolute w-full h-full object-cover object-center transform scale-110"
            />
            
            {/* Gradient Overlay */}
            <div 
              className="absolute inset-0 bg-gradient-to-b from-[#2D1B69]/60 via-[#4C1D95]/50 to-[#6B46C1]/40"
            />

            {/* Animated Gradient Lines */}
            <motion.div 
              className="absolute inset-0"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
            >
              <div 
                className="absolute bottom-0 left-0 right-0 h-2/3 w-full transform-gpu"
                style={{
                  background: `
                    repeating-linear-gradient(
                      90deg,
                      rgba(196,181,253,0.1) 0px,
                      rgba(196,181,253,0.1) 1px,
                      transparent 1px,
                      transparent 40px
                    ),
                    repeating-linear-gradient(
                      0deg,
                      rgba(196,181,253,0.1) 0px,
                      rgba(196,181,253,0.1) 1px,
                      transparent 1px,
                      transparent 40px
                    )
                  `,
                  transform: 'perspective(1000px) rotateX(60deg)',
                  transformOrigin: 'bottom',
                  backdropFilter: 'blur(1px)'
                }}
              />
            </motion.div>
          </div>
        </div>
      </div>

      {/* Hero Content */}
      <div className="relative z-10 container mx-auto px-4 h-screen flex flex-col items-center justify-center text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto space-y-8"
        >
          {/* Main Headline */}
          <motion.h1 
            className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-tight"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <span className="text-white">Your Business Journey</span>
            <br />
            <span className="bg-gradient-to-r from-[#C4B5FD] via-white to-[#C4B5FD] bg-clip-text text-transparent bg-300% animate-gradient">
              Starts with Us
            </span>
          </motion.h1>

          {/* Subheading */}
          <motion.p 
            className="text-lg sm:text-xl max-w-2xl mx-auto leading-relaxed px-4 font-medium"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            style={{
              background: 'linear-gradient(to right, rgba(255,255,255,0.95), rgba(237,233,254,0.95))',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Transform your vision into reality with our comprehensive corporate services.
            Expert guidance in the heart of UAE's most dynamic business hub.
          </motion.p>

          {/* CTA Buttons */}
          <motion.div 
            className="flex flex-col sm:flex-row justify-center items-center gap-4 mt-8 px-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            {/* Primary CTA */}
            <button
              onClick={() => navigate("/get-started")}
              className="group relative px-8 sm:px-10 py-4 sm:py-5 rounded-full bg-[#C4B5FD] text-[#2D1B69] font-medium 
                        hover:bg-white transition-all duration-300 transform hover:scale-105
                        shadow-lg hover:shadow-xl w-full sm:w-auto"
            >
              <span className="relative z-10 flex items-center justify-center gap-2 text-sm sm:text-base">
                Start Free Consultation
                <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 group-hover:translate-x-1 transition-transform duration-300" />
              </span>
            </button>

            {/* Secondary CTA - Updated to navigate to services */}
            <button
              onClick={() => navigate("/services")}
              className="group px-8 sm:px-10 py-4 sm:py-5 rounded-full border-2 border-[#C4B5FD] text-white font-medium 
                        hover:bg-[#C4B5FD]/10 transition-all duration-300 w-full sm:w-auto"
            >
              Our Services
            </button>
          </motion.div>
        </motion.div>

        {/* Scroll Indicator */}
        <motion.div 
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
          animate={{ 
            y: [0, 10, 0],
            opacity: [0.3, 1, 0.3]
          }}
          transition={{ 
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        >
          <div className="w-6 h-10 border-2 border-[#C4B5FD]/30 rounded-full flex items-start justify-center p-2">
            <motion.div 
              className="w-1.5 h-1.5 bg-[#C4B5FD] rounded-full"
              animate={{ y: [0, 16, 0] }}
              transition={{ 
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            />
          </div>
        </motion.div>

        {/* Floating Elements */}
        <div className="absolute top-1/4 left-1/4 w-32 h-32 bg-[#C4B5FD]/10 rounded-full blur-3xl"></div>
        <div className="absolute bottom-1/4 right-1/4 w-40 h-40 bg-[#856BAE]/10 rounded-full blur-3xl"></div>
      </div>
    </section>
  );
};

export default PerspectiveHero;