import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { 
  CalendarCheck, 
  Lightbulb,
  Users2,
  MapPin
} from 'lucide-react';

interface CTAContent {
  text: string;
  link: string;
  section: string;
  icon: React.ElementType;
}

const FloatingCTA = () => {
  const ctaContent: CTAContent[] = [
    {
      text: 'Schedule a Call',
      link: '/get-started',
      section: 'hero',
      icon: CalendarCheck
    },
    {
      text: 'Explore Solutions',
      link: '/services',
      section: 'why-us',
      icon: Lightbulb
    },
    {
      text: 'Partner With Us',
      link: '/contact',
      section: 'ideal-partner',
      icon: Users2
    },
    {
      text: 'Find Us',
      link: '/contact',
      section: 'locations',
      icon: MapPin
    }
  ];

  const [activeCTA, setActiveCTA] = useState<CTAContent>(ctaContent[0]);

  // Create refs for each section
  const [heroRef, heroInView] = useInView({ threshold: 0.5 });
  const [whyUsRef, whyUsInView] = useInView({ threshold: 0.5 });
  const [idealRef, idealInView] = useInView({ threshold: 0.5 });
  const [locationsRef, locationsInView] = useInView({ threshold: 0.5 });

  // Update active CTA based on visible section
  useEffect(() => {
    if (heroInView) setActiveCTA(ctaContent[0]);
    else if (whyUsInView) setActiveCTA(ctaContent[1]);
    else if (idealInView) setActiveCTA(ctaContent[2]);
    else if (locationsInView) setActiveCTA(ctaContent[3]);
  }, [heroInView, whyUsInView, idealInView, locationsInView]);

  const IconComponent = activeCTA.icon;

  return (
    <AnimatePresence mode='wait'>
      <motion.div
        key={activeCTA.text}
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100 }}
        className="fixed bottom-8 left-8 z-50"
      >
        <a
          href={activeCTA.link}
          className="group flex items-center gap-3 px-6 py-3 bg-gradient-to-r from-[#5D4A82] to-[#856BAE] 
            text-white rounded-full shadow-lg hover:shadow-xl transition-all duration-300 
            hover:scale-105"
        >
          <IconComponent className="w-5 h-5 group-hover:scale-110 transition-transform" />
          <span className="font-medium">{activeCTA.text}</span>
        </a>
      </motion.div>
    </AnimatePresence>
  );
};

// Export the refs to be used in the main layout
export const sectionRefs = {
  heroRef: null,
  whyUsRef: null,
  idealRef: null,
  locationsRef: null
};

export default FloatingCTA;