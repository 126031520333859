import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { HelmetProvider } from 'react-helmet-async';
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminLogin from "./components/auth/AdminLogin";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import ProfileProcessorPage from "./components/image/ProfileProcessorPage";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import BlogPage from "./pages/Blogpage";
import { AppointmentProvider } from "./contexts/AppointmentContext";
import About from "./pages/About";
import Contact from "./pages/contact";
import GetStartedPage from "./pages/GetStartedPage";
import SuccessPage from "./pages/SuccessPage";
import HomePage from "./pages/HomePage";
import FloatingWhatsApp from "./components/ui/FloatingWhatsApp";
import SEO from "./components/SEO";
import Company from "./pages/Company"; // Add this import

// Import Services Pages
import Services from "./pages/Services";
import LegalServices from "./pages/services/LegalServices";
import CorporateServices from "./pages/services/CorporateServices";
import AccountingServices from "./pages/services/AccountingServices";
import TaxServices from "./pages/services/TaxServices";
import AuditServices from "./pages/services/AuditServices";
import AdvisoryServices from "./pages/services/AdvisoryServices";
import FormationServices from "./pages/services/FormationServices";
import GovernanceServices from "./pages/services/GovernanceServices";
import BusinessAdministrationServices from "./pages/services/BusinessAdministrationServices";

const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <AuthProvider>
          <AppointmentProvider>
            <div className="app">
              <Header />
              <main>
                <Routes>
                  {/* Main Pages */}
                  <Route path="/" element={
                    <>
                      <SEO 
                        title="Luminous Bluewaters Consulting | Corporate Services Dubai"
                        description="Leading corporate service provider in Dubai, UAE. Specializing in business consulting, HR services, audit, and data analytics solutions."
                        canonicalUrl="https://lumi-blue.com/"
                      />
                      <HomePage />
                    </>
                  } />
                  
                  {/* About Page */}
                  <Route path="/about" element={
                    <>
                      <SEO 
                        title="About Us | Luminous Bluewaters Consulting"
                        description="Learn about Luminous Bluewaters Consulting's mission, values, and expertise in providing corporate services in Dubai, UAE."
                        canonicalUrl="https://lumi-blue.com/about"
                      />
                      <About />
                    </>
                  } />

                  {/* Company Page */}
                  <Route path="/company" element={
                    <>
                      <SEO 
                        title="Our Company | Luminous Bluewaters Consulting"
                        description="Meet our expert team at Luminous Bluewaters Consulting. Over 100 years of combined experience in corporate services, legal, finance, and business solutions."
                        canonicalUrl="https://lumi-blue.com/company"
                      />
                      <Company />
                    </>
                  } />

                  {/* Blog Page */}
                  <Route path="/blog" element={
                    <>
                      <SEO 
                        title="Blog | Luminous Bluewaters Consulting"
                        description="Stay informed with insights on business, corporate services, and UAE market trends from our expert team."
                        canonicalUrl="https://lumi-blue.com/blog"
                      />
                      <BlogPage />
                    </>
                  } />

                  {/* Get Started Page */}
                  <Route path="/get-started" element={
                    <>
                      <SEO 
                        title="Get Started | Luminous Bluewaters Consulting"
                        description="Begin your journey with Luminous Bluewaters Consulting. Schedule a consultation for our corporate services in Dubai."
                        canonicalUrl="https://lumi-blue.com/get-started"
                      />
                      <GetStartedPage />
                    </>
                  } />

                  {/* Contact Page */}
                  <Route path="/contact" element={
                    <>
                      <SEO 
                        title="Contact Us | Luminous Bluewaters Consulting"
                        description="Get in touch with our team for expert corporate services in Dubai. We're here to help your business succeed."
                        canonicalUrl="https://lumi-blue.com/contact"
                      />
                      <Contact />
                    </>
                  } />

                  {/* Services Main Page */}
                  <Route path="/services" element={
                    <>
                      <SEO 
                        title="Our Services | Luminous Bluewaters Consulting"
                        description="Explore our comprehensive range of corporate services in Dubai, from business setup to advisory and compliance solutions."
                        canonicalUrl="https://lumi-blue.com/services"
                      />
                      <Services />
                    </>
                  } />

                  {/* Service Pages with Enhanced SEO */}
                  <Route path="/services/legal" element={
                    <>
                      <SEO 
                        title="Legal Services Dubai | Luminous Bluewaters Consulting"
                        description="Expert legal services and consultation for businesses in Dubai. Comprehensive legal solutions for UAE companies."
                        canonicalUrl="https://lumi-blue.com/services/legal"
                      />
                      <LegalServices />
                    </>
                  } />

                  <Route path="/services/corporate" element={
                    <>
                      <SEO 
                        title="Corporate Services Dubai | Luminous Bluewaters Consulting"
                        description="Comprehensive corporate services in Dubai including company formation, restructuring, and business setup solutions."
                        canonicalUrl="https://lumi-blue.com/services/corporate"
                      />
                      <CorporateServices />
                    </>
                  } />

                  <Route path="/services/accounting" element={
                    <>
                      <SEO 
                        title="Accounting Services Dubai | Luminous Bluewaters Consulting"
                        description="Professional accounting services in Dubai including bookkeeping, financial reporting, and accounting system setup."
                        canonicalUrl="https://lumi-blue.com/services/accounting"
                      />
                      <AccountingServices />
                    </>
                  } />

                  <Route path="/services/tax" element={
                    <>
                      <SEO 
                        title="Tax Advisory Services Dubai | Luminous Bluewaters Consulting"
                        description="Expert tax advisory services in Dubai including VAT consultation, tax planning, and compliance solutions."
                        canonicalUrl="https://lumi-blue.com/services/tax"
                      />
                      <TaxServices />
                    </>
                  } />

                  <Route path="/services/audit" element={
                    <>
                      <SEO 
                        title="Audit Services Dubai | Luminous Bluewaters Consulting"
                        description="Comprehensive audit services in Dubai including internal audits, statutory audits, and compliance reviews."
                        canonicalUrl="https://lumi-blue.com/services/audit"
                      />
                      <AuditServices />
                    </>
                  } />

                  <Route path="/services/advisory" element={
                    <>
                      <SEO 
                        title="Business Advisory Services Dubai | Luminous Bluewaters Consulting"
                        description="Strategic business advisory services in Dubai. Expert consultation on business growth and risk management."
                        canonicalUrl="https://lumi-blue.com/services/advisory"
                      />
                      <AdvisoryServices />
                    </>
                  } />

                  <Route path="/services/formation" element={
                    <>
                      <SEO 
                        title="Company Formation Services Dubai | Luminous Bluewaters Consulting"
                        description="Complete company formation services in Dubai. Expert guidance on business setup and licensing."
                        canonicalUrl="https://lumi-blue.com/services/formation"
                      />
                      <FormationServices />
                    </>
                  } />

                  <Route path="/services/governance" element={
                    <>
                      <SEO 
                        title="Corporate Governance Services Dubai | Luminous Bluewaters Consulting"
                        description="Expert corporate governance solutions in Dubai. Establish robust governance frameworks and compliance."
                        canonicalUrl="https://lumi-blue.com/services/governance"
                      />
                      <GovernanceServices />
                    </>
                  } />

                  <Route path="/services/business-administration" element={
                    <>
                      <SEO 
                        title="Business Administration Services Dubai | Luminous Bluewaters Consulting"
                        description="Comprehensive business administration services in Dubai. Professional support for operations and management."
                        canonicalUrl="https://lumi-blue.com/services/business-administration"
                      />
                      <BusinessAdministrationServices />
                    </>
                  } />

                  {/* Admin and Protected Routes */}
                  <Route path="/login" element={<AdminLogin />} />
                  <Route path="/success" element={<SuccessPage />} />
                  <Route path="/admin" element={
                    <ProtectedRoute>
                      <AdminDashboard />
                    </ProtectedRoute>
                  } />
                  <Route path="/profile-processor" element={
                    <ProtectedRoute>
                      <ProfileProcessorPage />
                    </ProtectedRoute>
                  } />
                </Routes>
              </main>
              <Footer />
              <FloatingWhatsApp
                phoneNumber="+971562886878"
                message="Hello! I'm interested in exploring how Luminous Bluewaters Consulting can help transform my business. Could you provide more information about your services?"
              />
            </div>
          </AppointmentProvider>
        </AuthProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;