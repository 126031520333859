import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

const WhyUsHome = () => {
  const challengeSections = [
    {
      id: "legal",
      image: "/images/aboutimg/11.png",
      title: "Legal & Compliance Challenges",
      content: "SMEs in Dubai navigate a complex landscape of commercial regulations and licensing requirements. Many operate without proper legal documentation, risking unforeseen liabilities and disputes.",
      stats: "43% face talent issues tied to legal compliance"
    },
    {
      id: "hr",
      image: "/images/aboutimg/8.png",
      title: "Human Resource Management",
      content: "In a competitive market with high living costs, Dubai SMEs struggle with talent acquisition and retention. Managing a diverse workforce in a multicultural society presents unique challenges.",
      stats: "90% of private sector jobs, yet face low productivity"
    },
    {
      id: "tech",
      image: "/images/aboutimg/2.png",
      title: "Technology & Digital Transformation",
      content: "The rapid pace of technological advancement creates significant challenges for SMEs. From finding skilled developers to managing implementation costs, businesses struggle to keep up.",
      stats: "77% of UAE businesses investing in cloud technology"
    }
  ];

  const visionSection = {
    title: "Our Vision: Transforming Challenges into Opportunities",
    content: "At LBC, we understand these intricate challenges facing Dubai's SMEs. Our vision is to be more than a service provider – we aim to be a transformative partner in your business journey. By combining local expertise with global standards, we provide comprehensive solutions that address the core challenges while maintaining cost-effectiveness.",
  };

  return (
    <section className="py-20 bg-gradient-to-br from-[#5D4A82]/10 to-[#856BAE]/10">
      <div className="container mx-auto px-4">
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-4xl md:text-5xl font-bold text-[#5D4A82] mb-6">
            Why Choose LBC?
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            A vision born from market reality, bridging the gap between global expertise and local business needs
          </p>
        </motion.div>

        {/* Challenge Cards */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {challengeSections.map((section, index) => (
            <motion.div
              key={section.id}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="bg-white rounded-xl shadow-xl overflow-hidden group hover:shadow-2xl transition-all duration-300"
            >
              <div className="relative h-48 overflow-hidden">
                <img
                  src={section.image}
                  alt={section.title}
                  className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-500"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent backdrop-blur-[2px] p-4">
                  <p className="text-white font-semibold text-sm">
                    {section.stats}
                  </p>
                </div>
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold text-[#5D4A82] mb-3">
                  {section.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {section.content}
                </p>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Vision Section */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="bg-white/80 backdrop-blur-md rounded-xl p-8 shadow-xl"
        >
          <h2 className="text-3xl font-bold text-[#5D4A82] mb-6 text-center">
            {visionSection.title}
          </h2>
          <p className="text-lg text-gray-600 leading-relaxed max-w-4xl mx-auto text-center">
            {visionSection.content}
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default WhyUsHome;