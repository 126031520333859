import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../ui/Buttons";
import { FaCalendarAlt } from "react-icons/fa";
import logo from "../../assets/images/logo.png";

// Services data array
const services = [
  { name: "Accounting Services", path: "/services/accounting" },
  { name: "Advisory Services", path: "/services/advisory" },
  { name: "Audit Services", path: "/services/audit" },
  { name: "Corporate Services", path: "/services/corporate" },
  { name: "Legal Services", path: "/services/legal" },
  { name: "Tax Services", path: "/services/tax" },
];

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    setIsMenuOpen(false);
    setIsServicesOpen(false);
    navigate(path);
  };

  const NavButton = ({
    children,
    onClick,
    hasDropdown = false,
  }: {
    children: React.ReactNode;
    onClick?: () => void;
    hasDropdown?: boolean;
  }) => (
    <button
      onClick={onClick}
      className="text-[#5D4A82] hover:text-[#856BAE] transition-colors duration-300
        font-medium relative group py-2 cursor-pointer bg-transparent"
    >
      <div className="flex items-center">
        <span>{children}</span>
        {hasDropdown && (
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 9l-7 7-7-7"></path>
          </svg>
        )}
      </div>
      <span
        className="absolute bottom-0 left-0 w-0 h-0.5 bg-[#856BAE] 
        group-hover:w-full transition-all duration-300"
      ></span>
    </button>
  );

  return (
    <header className="fixed w-full top-0 z-50 bg-white/90 backdrop-blur-sm shadow-lg">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo Section */}
          <button
            className="flex items-center space-x-2 bg-transparent"
            onClick={() => navigate("/")}
            aria-label="Home"
          >
            <img
              src={logo}
              alt="LBG Logo"
              className="h-14 w-14 object-contain hover:scale-105 transition-transform duration-300"
            />
            <div className="text-[#5D4A82] font-bold text-xl hidden sm:block">
              Luminous Bluewaters Consulting
            </div>
          </button>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-8">
            <NavButton onClick={() => handleNavigation("/")}>Home</NavButton>

            <NavButton onClick={() => handleNavigation("/about")}>
              Why Us
            </NavButton>

            <NavButton onClick={() => handleNavigation("/company")}>
              Our Company
            </NavButton>

            {/* Services Dropdown */}
            <div className="relative group">
              <NavButton hasDropdown={true}>Services</NavButton>
              <div
                className="absolute left-0 mt-2 w-64 bg-white rounded-lg shadow-lg opacity-0 invisible
                             group-hover:opacity-100 group-hover:visible transition-all duration-300 py-2"
              >
                {services.map((service, index) => (
                  <button
                    key={index}
                    onClick={() => handleNavigation(service.path)}
                    className="w-full text-left px-4 py-2 text-[#5D4A82] hover:text-[#856BAE] hover:bg-purple-50
                              transition-colors duration-300"
                  >
                    {service.name}
                  </button>
                ))}
              </div>
            </div>

            <NavButton onClick={() => handleNavigation("/blog")}>
              Blog
            </NavButton>
          </nav>

          {/* Contact Button */}
          <div className="hidden md:block">
            <Button
              variant="cta"
              size="sm"
              onClick={() => handleNavigation("/get-started")}
              className="flex items-center gap-2"
            >
              <FaCalendarAlt />
              <span>Schedule a Free Call</span>
            </Button>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden p-2 text-[#5D4A82] hover:bg-purple-50 rounded-lg"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden py-4 border-t border-purple-100">
            <div className="flex flex-col space-y-4">
              <button
                onClick={() => handleNavigation("/")}
                className="text-left text-[#5D4A82] hover:text-[#856BAE] transition-colors duration-300
                  px-4 py-2 hover:bg-purple-50 rounded-lg cursor-pointer bg-transparent w-full"
              >
                Home
              </button>

              <button
                onClick={() => handleNavigation("/about")}
                className="text-left text-[#5D4A82] hover:text-[#856BAE] transition-colors duration-300
                  px-4 py-2 hover:bg-purple-50 rounded-lg cursor-pointer bg-transparent w-full"
              >
                Why Us
              </button>

              <button
                onClick={() => handleNavigation("/company")}
                className="text-left text-[#5D4A82] hover:text-[#856BAE] transition-colors duration-300
                  px-4 py-2 hover:bg-purple-50 rounded-lg cursor-pointer bg-transparent w-full"
              >
                Our Company
              </button>

              {/* Mobile Services Dropdown */}
              <div>
                <button
                  onClick={() => setIsServicesOpen(!isServicesOpen)}
                  className="text-left text-[#5D4A82] hover:text-[#856BAE] transition-colors duration-300
                    px-4 py-2 hover:bg-purple-50 rounded-lg cursor-pointer bg-transparent w-full flex justify-between items-center"
                >
                  <span>Services</span>
                  <svg
                    className={`w-4 h-4 transition-transform duration-300 ${
                      isServicesOpen ? "transform rotate-180" : ""
                    }`}
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M19 9l-7 7-7-7"></path>
                  </svg>
                </button>
                {isServicesOpen && (
                  <div className="ml-4 mt-2 space-y-2">
                    {services.map((service, index) => (
                      <button
                        key={index}
                        onClick={() => handleNavigation(service.path)}
                        className="text-left text-[#5D4A82] hover:text-[#856BAE] transition-colors duration-300
                          px-4 py-2 hover:bg-purple-50 rounded-lg cursor-pointer bg-transparent w-full"
                      >
                        {service.name}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              <button
                onClick={() => handleNavigation("/blog")}
                className="text-left text-[#5D4A82] hover:text-[#856BAE] transition-colors duration-300
                  px-4 py-2 hover:bg-purple-50 rounded-lg cursor-pointer bg-transparent w-full"
              >
                Blog
              </button>
              <Button
                variant="cta"
                size="sm"
                onClick={() => handleNavigation("/get-started")}
                className="mx-4 flex items-center justify-center gap-2"
              >
                <FaCalendarAlt />
                <span>Schedule a Free Call</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;