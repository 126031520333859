import React from 'react';
import ServiceTemplate from './ServiceTemplate';
import { bannerImages, processImages } from '../../config/images';

const AuditServices: React.FC = () => {
  const serviceData = {
    title: "Audit Services",
    description: "Comprehensive audit services to ensure accuracy, compliance, and transparency in your financial reporting.",
    heroImage: bannerImages.audit,
    keyFeatures: [
      {
        title: "Financial Audits",
        description: "Thorough examination of financial statements and records for accuracy and compliance.",
        icon: "search"
      },
      {
        title: "Internal Controls Review",
        description: "Assessment of internal control systems to ensure effectiveness and efficiency.",
        icon: "shield"
      },
      {
        title: "Compliance Audits",
        description: "Verification of adherence to regulatory requirements and industry standards.",
        icon: "check_circle"
      },
      {
        title: "Risk Assessment",
        description: "Identification and evaluation of potential financial and operational risks.",
        icon: "alert_triangle"
      },
      {
        title: "Performance Audits",
        description: "Evaluation of operational efficiency and effectiveness.",
        icon: "bar_chart"
      },
      {
        title: "Special Purpose Audits",
        description: "Customized audit services for specific requirements or transactions.",
        icon: "target"
      }
    ],
    benefits: [
      {
        title: "Assurance",
        description: "Gain confidence in the accuracy and reliability of your financial reporting.",
        icon: "check_square"
      },
      {
        title: "Risk Management",
        description: "Identify and address potential risks before they become issues.",
        icon: "shield"
      },
      {
        title: "Compliance",
        description: "Ensure adherence to all relevant regulations and standards.",
        icon: "book"
      },
      {
        title: "Improved Controls",
        description: "Strengthen internal controls and operational processes.",
        icon: "settings"
      }
    ],
    process: [
      {
        step: 1,
        title: "Planning",
        description: "Develop comprehensive audit strategy and approach based on your business needs.",
        image: processImages.planning
      },
      {
        step: 2,
        title: "Fieldwork",
        description: "Detailed examination and testing of financial records and controls.",
        image: processImages.implementation
      },
      {
        step: 3,
        title: "Analysis",
        description: "Thorough analysis of findings and identification of key areas for improvement.",
        image: processImages.consultation
      },
      {
        step: 4,
        title: "Reporting",
        description: "Detailed reporting of findings with actionable recommendations.",
        image: processImages.support
      }
    ]
  };

  return <ServiceTemplate {...serviceData} />;
};

export default AuditServices;