import React from 'react';
import ServiceTemplate from './ServiceTemplate';
import { bannerImages, processImages } from '../../config/images';

const LegalServices: React.FC = () => {
  const serviceData = {
    title: "Legal Services",
    description: "Comprehensive legal solutions to protect your business interests and ensure regulatory compliance.",
    heroImage: bannerImages.legal,
    keyFeatures: [
      {
        title: "Corporate Law",
        description: "Expert guidance on corporate legal matters and compliance requirements.",
        icon: "briefcase"
      },
      {
        title: "Contract Management",
        description: "Comprehensive contract drafting, review, and management services.",
        icon: "file_text"
      },
      {
        title: "Legal Compliance",
        description: "Ensure adherence to all relevant laws and regulations.",
        icon: "shield"
      },
      {
        title: "Dispute Resolution",
        description: "Professional handling of legal disputes and negotiations.",
        icon: "message_square"
      },
      {
        title: "Intellectual Property",
        description: "Protection and management of intellectual property rights.",
        icon: "award"
      },
      {
        title: "Legal Advisory",
        description: "Strategic legal advice for business decisions and operations.",
        icon: "book"
      }
    ],
    benefits: [
      {
        title: "Legal Protection",
        description: "Comprehensive protection of your business interests and assets.",
        icon: "shield_check"
      },
      {
        title: "Risk Management",
        description: "Proactive identification and mitigation of legal risks.",
        icon: "alert_triangle"
      },
      {
        title: "Expert Guidance",
        description: "Access to experienced legal professionals and expertise.",
        icon: "users"
      },
      {
        title: "Peace of Mind",
        description: "Confidence in your legal compliance and business protection.",
        icon: "check_circle"
      }
    ],
    process: [
      {
        step: 1,
        title: "Initial Consultation",
        description: "Comprehensive review of your legal needs and requirements.",
        image: processImages.consultation
      },
      {
        step: 2,
        title: "Strategy Development",
        description: "Creation of tailored legal solutions and strategies.",
        image: processImages.planning
      },
      {
        step: 3,
        title: "Implementation",
        description: "Execution of legal services and solutions with precision.",
        image: processImages.implementation
      },
      {
        step: 4,
        title: "Ongoing Support",
        description: "Continuous legal support and monitoring of compliance.",
        image: processImages.support
      }
    ]
  };

  return <ServiceTemplate {...serviceData} />;
};

export default LegalServices;