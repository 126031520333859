import React from 'react';
import ServiceTemplate from './ServiceTemplate';
import { bannerImages, processImages } from '../../config/images';

const AdvisoryServices: React.FC = () => {
  const serviceData = {
    title: "Advisory Services",
    description: "Strategic business advisory services to help you make informed decisions and achieve your business goals.",
    heroImage: bannerImages.advisory,
    keyFeatures: [
      {
        title: "Strategic Planning",
        description: "Comprehensive strategic planning to help you achieve your business objectives.",
        icon: "target"
      },
      {
        title: "Business Analysis",
        description: "In-depth analysis of your business operations and market position.",
        icon: "chart_bar"
      },
      {
        title: "Risk Management",
        description: "Identify and mitigate potential business risks before they impact your operations.",
        icon: "shield"
      },
      {
        title: "Growth Strategy",
        description: "Develop and implement strategies for sustainable business growth.",
        icon: "trending_up"
      },
      {
        title: "Performance Optimization",
        description: "Improve operational efficiency and business performance.",
        icon: "settings"
      },
      {
        title: "Change Management",
        description: "Guide your organization through transformational changes effectively.",
        icon: "refresh"
      }
    ],
    benefits: [
      {
        title: "Expert Guidance",
        description: "Access to experienced business advisors who understand your industry.",
        icon: "users"
      },
      {
        title: "Strategic Direction",
        description: "Clear roadmap for achieving your business objectives.",
        icon: "compass"
      },
      {
        title: "Risk Reduction",
        description: "Proactive identification and management of business risks.",
        icon: "shield_check"
      },
      {
        title: "Improved Performance",
        description: "Enhanced business operations and increased profitability.",
        icon: "chart_line"
      }
    ],
    process: [
      {
        step: 1,
        title: "Initial Assessment",
        description: "Comprehensive review of your current business situation and objectives.",
        image: processImages.consultation
      },
      {
        step: 2,
        title: "Strategy Development",
        description: "Creation of tailored strategies aligned with your business goals.",
        image: processImages.planning
      },
      {
        step: 3,
        title: "Implementation",
        description: "Execution of agreed strategies with regular monitoring and adjustments.",
        image: processImages.implementation
      },
      {
        step: 4,
        title: "Review and Optimization",
        description: "Continuous evaluation and refinement of implemented strategies.",
        image: processImages.support
      }
    ]
  };

  return <ServiceTemplate {...serviceData} />;
};

export default AdvisoryServices;