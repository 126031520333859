const getApiUrl = () => {
  return process.env.NODE_ENV === "production"
    ? "https://lumi-blue.com/api" // Your production domain
    : "http://localhost:3001/api";
};

export const API_URL = getApiUrl();

export const apiEndpoints = {
  appointments: `${API_URL}/appointments`,
};