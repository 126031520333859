import React from 'react';
import {
  BookOpen,
  BarChart2,
  DollarSign,
  Users,
  FileText,
  PieChart,
  ClipboardCheck,
  Clock,
  Shield,
  TrendingUp,
  Award,
  HeartHandshake,
  Lightbulb,
  Target,
  Briefcase,
  BarChart
} from 'lucide-react';

export const ServiceIcons = {
  // Accounting Icons
  bookkeeping: BookOpen,
  financial_statements: BarChart2,
  bank_reconciliation: DollarSign,
  payroll: Users,
  tax_planning: FileText,
  financial_analysis: PieChart,
  
  // General Icons
  accuracy: ClipboardCheck,
  time: Clock,
  compliance: Shield,
  growth: TrendingUp,
  quality: Award,
  support: HeartHandshake,
  innovation: Lightbulb,
  strategy: Target,
  professional: Briefcase,
  performance: BarChart,
  
  // Function to get icon component by name
  getIcon: (name: string) => {
    const IconComponent = ServiceIcons[name as keyof typeof ServiceIcons];
    return IconComponent || BookOpen; // Default to BookOpen if icon not found
  }
};

export default ServiceIcons;