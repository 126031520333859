import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import ServiceCards from '../components/about/ServiceCards';
import AboutVideo from '../components/about/video/AboutVideo';

const About = () => {
  const visionSection = {
    title: "Our Vision: Transforming Challenges into Opportunities",
    content: "At LBC, we understand these intricate challenges facing Dubai's SMEs. Our vision is to be more than a service provider – we aim to be a transformative partner in your business journey. By combining local expertise with global standards, we provide comprehensive solutions that address the core challenges while maintaining cost-effectiveness.",
    solutions: [
      {
        title: "Integrated Legal & Compliance Support",
        description: "Professional guidance through Dubai's regulatory landscape, ensuring full compliance while minimizing risks."
      },
      {
        title: "Strategic HR Solutions",
        description: "Comprehensive HR services that address recruitment, retention, and cultural integration challenges."
      },
      {
        title: "Technology Implementation & Support",
        description: "Affordable digital transformation solutions with ongoing support and expertise."
      },
      {
        title: "Financial & Audit Excellence",
        description: "Professional audit preparation and financial management services that enhance your business credibility."
      }
    ]
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#5D4A82] to-[#856BAE]">
      {/* Video Section */}
      <AboutVideo />

      {/* Vision Section */}
      <motion.section 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="container mx-auto px-4 py-16"
      >
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white/20 backdrop-blur-md rounded-xl p-8"
        >
          <h2 className="text-3xl font-bold text-white mb-8 text-center">
            {visionSection.title}
          </h2>
          <p className="text-lg text-white/90 leading-relaxed mb-12 max-w-4xl mx-auto text-center">
            {visionSection.content}
          </p>
          <div className="grid md:grid-cols-2 gap-8">
            {visionSection.solutions.map((solution, index) => (
              <motion.div
                key={index}
                className="bg-white/10 rounded-xl p-6 hover:bg-white/20 transition-all duration-300"
                whileHover={{ scale: 1.02 }}
              >
                <h3 className="text-xl font-bold text-white mb-3">
                  {solution.title}
                </h3>
                <p className="text-white/80">
                  {solution.description}
                </p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Services Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="mt-20"
        >
          <h2 className="text-4xl font-bold text-white text-center mb-12">
            Our Services
          </h2>
          <ServiceCards />
        </motion.div>
      </motion.section>
    </div>
  );
};

export default About;